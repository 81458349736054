@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

.checkbox {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
}

.label {
    font-size: 16px;
    line-height: 26px;
    color: $color-primary;
    cursor: pointer;

    &:not(:disabled):hover, 
    &:not(:disabled):active, 
    &:visited:not(:disabled):hover, 
    &:visited:not(:disabled):active, 
    &:not(:disabled):hover, 
    &:not(:disabled):active {
        color: #165a4b;
        text-decoration: underline;
    }
}