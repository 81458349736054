@import "~@ui/styles/tools";
@import "../../../../styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1350px) {
        @content;
    }
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 24px;
    margin-bottom: 57px;

    @include media-tablet {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        margin-bottom: 0;
    }

    @include media-tablet-portrait {
        grid-template-columns: 1fr;
    }

    @include media-mobile {
        gap: 12px;
        margin-bottom: 32px;
    }
}

.block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 240px;
    
    @include medium-breakpoint {
        min-height: 180px;
    }

    @include media-tablet-portrait {
        min-height: 125px;
        padding: 12px;
    }

    @include media-mobile {
        min-height: 80px;
    }
}

.block__default {
    background: $color-page-background !important;

    .block__value {
        color: $color-title-active;
    }

    .block__footer-title {
        color: $color-text-light;
    }
}

.block__top {
    background: $color-primary !important;

    .block__value {
        color: $color-off-white;
    }

    .block__footer-title {
        color: $color-off-white;
    }
}

.block__value {
    font-size: 72px;
    line-height: 1;
    font-weight: 400;
    white-space: nowrap;
    margin: 0;
    width: fit-content;

    @include medium-breakpoint {
        font-size: 48px;;
    }

    @include media-tablet-portrait {
        font-size: 44px;
    }

    @include media-mobile {
        font-size: 27px;
    }
}

.block__value-link {
    text-decoration: none !important;
    transition: .3s;
}

.block__value-postfix {
    font-size: 48px;
    text-transform: uppercase;

    @include medium-breakpoint {
        font-size: 36px;
    }

    @include media-tablet-portrait {
        font-size: 30px;
    }
    
    @include media-mobile {
        font-size: 18px;
    }

    @include media-mobile-xs {
        font-size: 14px;
    }
}

.block__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.block__footer-title {
    font-size: 20px;
    max-width: 315px;
    margin-bottom: 0;
    
    @include medium-breakpoint {
        font-size: 18px;
        max-width: 235px;
    }

    @include media-tablet {
        max-width: 100%;
    }

    @include media-tablet-portrait {
        font-size: 16px;
    }

    @include media-mobile {
        font-size: 14px;
        line-height: 16px;
        max-width: 100%;
    }

    @include media-mobile-xs {
        font-size: 14px;
        line-height: 15px;
    }
}

.block__badge {
    position: absolute;
    top: 0;
    right: 20px;

    > img {
        width: 72px;
        height: auto;
    }

    @include media-tablet-portrait {
        > img {
            width: 50px;
        }
    }

    @include media-mobile {
        > img {
            width: 40px;
        }
    }
}
